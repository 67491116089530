import React from 'react'
import SEO from '../components/seo'
import Container from '../components/layout/layout'
import Layout from '../containers/layout'
import { graphql } from 'gatsby'
import VideoBanner from '../components/video-banner/video-banner'

export const query = graphql `
  query ThankYouPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)thankyou/" }) {
        id
      title
      _rawBody
			_rawSubtitle
      useVideo
      pageBranding {
        asset {
          _id
        }
      }
      mainVideo {
        embed {
          _type
          asset {
            url
          }
        }
      }
      mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
    }
  }
`
const ThankYouPage = (props) => {
  const { data, errors } = props
  const page = (typeof data === "object" && Object.keys(data).length > 0 ? data.page : {}); 

  console.log(page)
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  if (!page) {
     throw new Error(
       'Missing "Thank You" page data. Open the studio at http://localhost:3333 and add "Thank You" page data and restart the development server.'
     )
  }

 return (
    <Layout>
      <SEO title='Thank You For Contacting Us' />
			<VideoBanner { ...page}/>
    </Layout>
  )
}

export default ThankYouPage
